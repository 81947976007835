.buttons-frame {
	width: 450px;
	height: 500px;
	display: grid;
	grid-template-columns: 75px 193px 107px 75px;
	grid-template-rows: 25px 100px 140px 170px;
	grid-template-areas:
		"upperBtns upperBtns upperBtns upperBtns"
		"topFns topFns ctrlBtn ctrlBtn"
		"midFns midFns midFns midFns"
		"leftFns numbers numbers rightFns";
	align-content: center;
	justify-content: center;
}

.numbers-frame {
	height: 165px;
	align-self: end;
	justify-self: center;
	display: grid;
	grid-template-columns: 100px auto 100px;
	gap: 1.5px 2px;
	grid-area: numbers;
}

.upperFns-frame {
	grid-area: upperBtns;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-around;
}

.upperFns {
	height: 24px;
}

.ctrlBtns-frame {
	grid-area: ctrlBtn;
	display: grid;
	justify-content: space-evenly;
	grid-template-columns: auto auto auto;
	grid-template-rows: auto auto auto;
	grid-template-areas:
		".    up      ."
		"left  .  right"
		".   down     .";
}

#root > div > div > div.buttons-frame > div.ctrlBtns-frame > button.up {
	grid-area: up;
	width: 50px;
	height: 30px;
	background-color: rgb(196, 255, 240);
}

#root > div > div > div.buttons-frame > div.ctrlBtns-frame > button.left {
	grid-area: left;
	width: 50px;
	height: 30px;
	background-color: rgb(196, 255, 240);
}

#root > div > div > div.buttons-frame > div.ctrlBtns-frame > button.right {
	grid-area: right;
	width: 50px;
	height: 30px;
	background-color: rgb(196, 255, 240);
}

#root > div > div > div.buttons-frame > div.ctrlBtns-frame > button.down {
	grid-area: down;
	width: 50px;
	height: 30px;
	background-color: rgb(196, 255, 240);
}

.topFns-frame {
	grid-area: topFns;
	display: grid;
	grid-template-columns: auto auto auto;
	grid-template-rows: auto auto;
	align-items: center;
	column-gap: 5px;
}

.midFns-frame {
	grid-area: midFns;
	display: grid;
	grid-template-columns: auto auto auto auto auto;
	grid-template-rows: auto auto auto;
	align-items: center;
	justify-content: space-around;
	gap: 0px 6.5px;
}

.rightFns-frame {
	height: 165px;
	width: 85px;
	grid-area: rightFns;
	display: grid;
	justify-self: end;
	align-self: end;
	row-gap: 2px;
}

.leftFns-frame {
	height: 165px;
	width: 85px;
	grid-area: leftFns;
	display: grid;
	justify-self: start;
	align-self: end;
	row-gap: 8px;
}

button.rightFns,
button.leftFns,
button.midFns,
button.topFns {
	width: 70px;
	height: 35px;
}

button.rightFns.enter {
	height: 50px;
}

button {
	width: 80px;
	height: 40px;
	background-color: rgb(252, 154, 221);
	border: 2px solid grey;
	justify-self: center;
	border-radius: 20%;
}

button.numbers {
	display: grid;
	grid-template-columns: 16px 32px 16px;
	grid-template-rows: 14px 18px;
	grid-template-areas:
		"sec . alph"
		". main .";
	align-content: space-evenly;
	justify-items: center;
}

button.rightFns,
button.leftFns,
button.midFns,
button.topFns {
	display: grid;
	grid-template-columns: 14px 30px 14px;
	grid-template-rows: 13px 18px;
	grid-template-areas:
		"sec . alph"
		". main .";
	align-content: space-evenly;
	justify-items: center;
}

span.mainChar {
	grid-area: main;
	width: 40px;
	font-size: 16px;
	color: #40404c;
}

span.alphaChar {
	grid-area: alph;
	width: 20px;
	color: #0007f0;
}

span.secChar {
	grid-area: sec;
	width: 20px;
	color: #2c613b;
	font-size: 10px;
	display: inline-flex;
}
