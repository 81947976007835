div.screen {
	position: relative;
	width: 430px;
	height: 320px;
	border: 0.5mm solid grey;
	border-radius: 2.5%;
	margin-top: 4mm;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	flex-direction: column;
	background-color: #ffffe8;
	color: #000;
}

div.screen.stat-screen {
	justify-content: flex-start;
	align-items: flex-start;
}

div.statscreen-title__container {
	display: flex;
	width: 100%;
	justify-content: space-evenly;
	align-items: flex-start;
}

div.scroller {
	width: 430px;
	height: 300px;
	overflow: auto;
}

h2.focused {
	border-bottom: solid black 1px;
}

ul.statScreen-itemList {
	list-style: none;
	font-weight: 500;
	font-size: 15pt;
	list-style: none;
	position: relative;
}

ul.windowList,
ul.yPlotList {
	height: 250px;
	width: 430px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-start;
	margin-bottom: 0px;
}

h2.window-title {
	position: absolute;
	top: -15px;
	left: 15px;
}

li.window-config,
li.yplot-config {
	font-weight: 500;
	font-size: 15pt;
	list-style: none;
	position: relative;
	margin-left: 20px;
}

div.windowOptions p.info {
	font-weight: 400;
	font-size: 10pt;
	margin: 5px 0 0 165px;
	margin-bottom: 0px;
}

div.welcome-screen {
	align-self: center;
	margin-bottom: 40px;
	display: grid;
	justify-items: center;
}

div.logScreen {
	align-self: center;
	margin-bottom: 40px;
	display: grid;
}

p.log {
	padding: 10px 0 10px 0;
	margin: 0;
}

p.log.selected {
	border: 1px #33333377 dashed;
}

span.list-key {
	width: 20px;
	display: inline-block;
}

span.yPlot-listItem__selector {
	width: 18px;
	height: 20px;
	display: inline-flex;
	justify-content: center;
	align-items: flex-end;
}

span.active {
	background-color: #444444ff;
	color: #ffffff;
	border-radius: 10%;
}

span.data {
	margin: 0 5px 0 0;
	width: 425px;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	color: rgb(96, 34, 202);
	border-bottom: 1px dashed #666666;
}

span.user-input {
	width: 430px;
	height: 20px;
	border-top: 1px solid #000000;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin: -1px;
}

p.input {
	margin: 0 0 0 5px;
}

span.pointer {
	border-radius: 10%;
	color: #ffffff;
	background-color: #444444ff;
	animation: blinkingBG 1s infinite;
}

.mathScreen-body {
	display: flex;
	flex-flow: column;
	align-items: flex-start;
	margin-left: 10px;
	margin-top: 8px;
}
table {
	width: 100%;
	height: 100%;
}
.mathScreen {
	width: 100%;
	height: 100%;
}
.mathMode_selected {
	background-color: #3f3f3f;
	color: #f3f3f3;
}

.tableHeaders {
	padding: 3px 5px;
}

.mathScreen tr {
	display: flex;
	justify-content: space-around;
}

.sq-root__sym {
	position: absolute;
}

@keyframes blinkingBG {
	0%,
	49% {
		background-color: rgba(68, 68, 68, 0.768);
		color: #ffffff;
	}
	50%,
	100% {
		background-color: #ffffe821;
		color: #000;
	}
}
