body {
	display: flex;
	justify-content: center;
	align-items: center;
}

*,
body,
button,
input,
select,
textarea,
canvas {
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	outline: 0;
}

.frame {
	width: 470px;
	height: 840px;
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
	border: 5px solid rgb(126, 126, 126);
	border-radius: 8%;
	background-color: #a0d5f6;
}
